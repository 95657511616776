<template>
  <div class="row">
    <div v-if="!isLoad" class="col-12">
      <div v-if="materials != null" class="row">
        <div class="col-12">
          <div class="card d-block border-0 rounded-lg overflow-hidden p-4 shadow-xss">
            <div class="row">
              <div class="col-lg-3 mb-lg-0 mb-5">
                <div class="card border-0 mb-0 rounded-lg overflow-hidden">
                  <div class="player shadow-none">
                    <img v-if="materials.thumbnail" :src=" materials.thumbnail" class="w-100" :alt=" materials.thumbnail">
                  </div>
                </div>
              </div>
              <div class="col-lg-9">
                <div class="float-right">
                  <span class="dot ml-2 mr-2 d-inline-block btn-round-xss bg-current"></span>
                  <span class="font-xssss fw-700 text-grey-900 d-inline-block ml-0 text-dark">{{ materials.created_by?.name || '-'}}</span>
                </div>
                <h2 class="fw-700 font-md d-block lh-4 mb-3 text-current">{{materials.title}}</h2>
                <div v-if="materials.type == 4 && materials.path">
                  <a v-if="materials.path.split('.').pop().split(/\#|\?/)[0] == 'epub'" :href="reader + '?book=' +  materials.path.split('.epub')[0]" target="_blank" class="rounded-lg text-white bg-current w150 p-2 lh-32 font-xs text-center fw-500 d-inline-block mb-4 mt-2"><i class="feather-external-link mr-2"></i>Read</a>
                  <a v-if="materials.path.split('.').pop().split(/\#|\?/)[0] == 'html'" :href=" materials.path" target="_blank" class="rounded-lg text-white bg-current w150 p-2 lh-32 font-xs text-center fw-500 d-inline-block mb-4 mt-2"><i class="feather-external-link mr-2"></i>Read</a>
                </div>
                <h2 class="fw-700 font-sm mb-2 mt-1">Description</h2>
                <p class="font-xssss fw-500 lh-28 text-grey-600 mb-0">{{materials.description}}</p>
              </div>
              <div v-if="materials.type == 1 && materials.path" class="col-12 text-center py-4">
                <video class="w-100 h-auto" controls controlsList="nodownload">
                  <source :src=" materials.path" type="video/mp4">
                </video>
              </div>
              <div v-if="materials.type == 2 && materials.path" class="col-12 text-center py-4">
                <embed type="text/html" :src=" materials.path" id="embeded-media" class="w-100 mh-100vh">
                <button @click="$event => openFullscreen()" class="btn-fullscreen bg-current border-0 text-white fw-600 text-uppercase font-xssss rounded-lg d-block d-md-inline-block mt-4 lh-34 text-center ls-3"> Fullscreen Mode </button>
              </div>
              <div v-if="materials.type == 3 && materials.path" class="col-12 text-center py-4">
                <audio class="w-100" controls controlsList="nodownload">
                  <source :src=" materials.path" type="audio/mpeg">
                  Your browser does not support the audio element.
                </audio>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="row">
        <div class="col-12 text-center py-3">
          <img :src="'/images/empty.png'" alt="" width="300">
          <h1 class="text-muted">Data don't exist</h1>
        </div>
      </div>
    </div>
    <div v-else class="col-12 text-center py-3">
      <div class="my-3">
        <div class="spinner-grow text-warning mx-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-warning mx-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-warning mx-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-warning mx-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-warning mx-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-warning mx-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  name: "App",
  data() {
    return {
      isLoad: true,
      paramsId: this.$route.params.idCourse,
      idMaterial: this.$route.params.idMaterial,
      media: "",
      reader: process.env.VUE_APP_URL_READER,
      materials: null,
    };
  },
  methods: {
    async getMaterials(){
      await axios.get(`${process.env.VUE_APP_URL_API}/core/material/detail?slug_course=${this.paramsId}&id=${this.idMaterial}`, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`
        }
      }).then(res => {
        this.materials = res.data
        this.isLoad = false
      }).catch((err) => console.error(err.response))
    },
    openFullscreen() {
      const elem = document.getElementById('embeded-media');
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }
    }

  },
  created(){
    this.getMaterials()
  },
};
</script>
<style scoped>
    .mh-100vh {
        min-height: 100vh;
    }

    .btn-fullscreen {
      width: 200px;
    }

    @media screen and (max-width: 576px) {
      .btn-fullscreen {
        width: 100%;
      }
    }
</style>
